// TODO: allow option to drop progress below the slide image
// TODO: give options for the transition style (fade, slide)
// TODO: consider animating the content box (maybe it slides and fades in independently of the image)

addEventListener("DOMContentLoaded", (event) => {
    const sliders = document.querySelectorAll('.coldbrew-slider');
    sliders.forEach(sliderElement => {
        new Slider(sliderElement);
    })
 });

class Slider {
    constructor (element) {
        this.slider = element;
        this.headerHeight = this.calculateHeaderHeight();
        this.sliderType = this.getSliderType();
        this.slides = this.getSlides();
        this.slideDuration = this.getSlideDuration();
        this.currentSlideIndex = 0;
        this.intervalId = null;

        // get slider config settings
        this.showNav = this.slider.getAttribute('data-slider-shownav') === 'true';
        this.showProgress = this.slider.getAttribute('data-slider-showprogress') === 'true';
        this.manualAdvance = this.slider.getAttribute('data-slider-manual-advance') === 'true';
        this.circleNav = this.slider.getAttribute('data-slider-circlenav') === 'true';

        if (this.slides.length > 1) {
            this.initSlider();
            this.initEvents();
        } else if (this.slides.length === 1) {
            this.showNav = false;
            this.showProgress = false;
            this.initSlider();
        } else {
            this.slider.style.display = 'none';
            console.error('No slides found');
        }
    }

    calculateHeaderHeight() {
        try {
            const header = document.querySelector('#header');
            return header ? header.offsetHeight : 0;
        } catch (error) {
            console.error('Error calculating header height:', error);
            return 0;
        }
    }

    getSliderType() {
        try {
            return this.slider.getAttribute('data-slider-type').toLowerCase();
        } catch (error) {
            this.slider.style.display = 'none';
            console.error('Slider type not found');
            return null;
        }
    }

    // getSlides() {
    //     return this.slider.querySelectorAll('.slide');
    // }

    getSlides() {
        const slides = this.slider.querySelectorAll('.slide');
        slides.forEach((slide, index) => {
            slide.setAttribute('role', 'group');
            slide.setAttribute('aria-roledescription', 'slide');
            slide.setAttribute('aria-label', `Slide ${index + 1} of ${slides.length}`);
        });
        return slides;
    }

    getSlideDuration() {
        try {
            let duration = parseInt(this.slider.getAttribute('data-slider-duration'));
            return duration >= 3 ? duration * 1000 : 5000;
        } catch (error) {
            return 5000;
        }
    }

    initSlider() {
        switch (this.sliderType) {
            case 'hero':
            case 'projects':
                this.slider.style.maxHeight = `calc(100vh - ${this.headerHeight}px)`;
                break;
            default:
                this.slider.style.display = 'none';
                return;
        }

        // init navs
        if (this.showNav) {
            this.createNavigation();

            const navButtons = this.slider.querySelectorAll('.nav');
            navButtons.forEach(button => {
                button.addEventListener('keydown', (e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        button.click();
                    }
                });
            });
        }

        // init progress indicator
        if (this.showProgress) {
            this.createProgressIndicator();
        }

        // activate first slide
        this.slides[this.currentSlideIndex].classList.add('active');

        // start auto-slide functionality (unless only 1 slide or manual advance)
        if (this.slides.length > 1 && !this.manualAdvance) {
            this.startAutoSlide();
        }
    }

    initEvents() {
        if (this.showNav) {
            const navForward = this.slider.querySelector('.nav-forward');
            const navBackward = this.slider.querySelector('.nav-backward');

            navForward.addEventListener('click', () => {
                this.advanceSlide();

                if (!this.manualAdvance) {
                    this.resetAutoSlide();
                }
            });

            navBackward.addEventListener('click', () => {
                this.reverseSlide();

                if (!this.manualAdvance) {
                    this.resetAutoSlide();
                }
            });
        }

        if (this.showProgress) {
            this.initProgressDotEvents();
        }
    }

    startAutoSlide() {
        this.intervalId = setInterval(() => {
            this.advanceSlide();
        }, this.slideDuration);
    }

    resetAutoSlide() {
        clearInterval(this.intervalId);
        this.startAutoSlide();
    }

    // advanceSlide() {
    //     this.slides[this.currentSlideIndex].classList.remove('active');
    //     this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
    //     this.slides[this.currentSlideIndex].classList.add('active');

    //     if (this.showProgress) {
    //         this.updateProgressIndicator();
    //     }
    // }
    advanceSlide() {
        this.slides[this.currentSlideIndex].classList.remove('active');
        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
        this.slides[this.currentSlideIndex].classList.add('active');

        if (this.showProgress) {
            this.updateProgressIndicator();
        }

        // Announce the new slide
        const slideDescription = this.slides[this.currentSlideIndex].getAttribute('aria-label');
        this.announceSlideChange(slideDescription);
    }

    announceSlideChange(description) {
        const liveRegion = document.getElementById('slide-live-region');
        if (!liveRegion) {
            const region = document.createElement('div');
            region.setAttribute('id', 'slide-live-region');
            region.setAttribute('aria-live', 'polite');
            region.setAttribute('role', 'status');
            region.style.position = 'absolute';
            region.style.clip = 'rect(0 0 0 0)'; // Hide visually but remain accessible to screen readers
            document.body.appendChild(region);
        } else {
            liveRegion.textContent = description;
        }
    }

    reverseSlide() {
        this.slides[this.currentSlideIndex].classList.remove('active');
        this.currentSlideIndex = (this.currentSlideIndex - 1 + this.slides.length) % this.slides.length;
        this.slides[this.currentSlideIndex].classList.add('active');

        if (this.showProgress) {
            this.updateProgressIndicator();
        }
    }

    createNavigation() {
        // create back button
        const navBackward = document.createElement('button');
        navBackward.classList.add('nav', 'nav-backward');
        if (this.circleNav) navBackward.classList.add('circle-buttons');
        navBackward.setAttribute('aria-label', 'Previous Slide');
        navBackward.innerHTML = '<i class="fa-solid fa-chevron-left"></i>';
        this.slider.appendChild(navBackward);

        // create forward button
        const navForward = document.createElement('button');
        navForward.classList.add('nav', 'nav-forward');
        if (this.circleNav) navForward.classList.add('circle-buttons');
        navForward.setAttribute('aria-label', 'Next Slide');
        navForward.innerHTML = '<i class="fa-solid fa-chevron-right"></i>';
        this.slider.appendChild(navForward);
    }

    createProgressIndicator() {
        const progressContainer = document.createElement('div');
        progressContainer.classList.add('progress-indicator');
        progressContainer.setAttribute('aria-label', 'Slide Progress Indicator');
        this.slider.appendChild(progressContainer);

        this.slides.forEach((slide, index) => {
            const dot = document.createElement('i');
            dot.className = 'fa-regular fa-circle'; // default class
            dot.setAttribute('aria-hidden', 'true');
            dot.setAttribute('data-slide-index', index);
            dot.style.cursor = 'pointer';

            if (index === this.currentSlideIndex) {
                dot.classList.add('active', 'fa-solid');
            }

            progressContainer.appendChild(dot);
        })
    }

    updateProgressIndicator() {
        const dots = this.slider.querySelectorAll('.progress-indicator i');
        dots.forEach((dot, index) => {
            dot.classList.remove('active', 'fa-solid');
            dot.classList.add('fa-regular');

            if (index === this.currentSlideIndex) {
                dot.classList.add('active', 'fa-solid');
            }
        });
    }

    initProgressDotEvents() {
        const dots = this.slider.querySelectorAll('.progress-indicator i');
        dots.forEach(dot => {
            dot.addEventListener('click', (event) => {
                const targetSlideIndex = parseInt(event.currentTarget.getAttribute('data-slide-index'));

                if (targetSlideIndex !== this.currentSlideIndex) {
                    this.goToSlide(targetSlideIndex);

                    if (!this.manualAdvance) {
                        this.resetAutoSlide();
                    }
                }
            });
        });
    }

    // goToSlide(slideIndex) {
    //     this.slides[this.currentSlideIndex].classList.remove('active');
    //     this.currentSlideIndex = slideIndex;
    //     this.slides[this.currentSlideIndex].classList.add('active');

    //     if (this.showProgress) {
    //         this.updateProgressIndicator();
    //     }
    // }

    goToSlide(slideIndex) {
        this.slides[this.currentSlideIndex].classList.remove('active');
        this.currentSlideIndex = slideIndex;
        this.slides[this.currentSlideIndex].classList.add('active');

        if (this.showProgress) {
            this.updateProgressIndicator();
        }

        // Optionally, manage focus after slide change
        const firstFocusableElement = this.slides[this.currentSlideIndex].querySelector('a, button, input, [tabindex="0"]');
        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }
}