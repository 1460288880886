// addEventListener('DOMContentLoaded', function(event) {
//     const questionBlocks = document.querySelectorAll('#faq-container article .question-block');

//     // open the first faq
//     questionBlocks[0].parentElement.setAttribute('data-expanded', 'true');
//     questionBlocks[0].parentElement.setAttribute('aria-expanded', 'true');

//     questionBlocks.forEach(block => {
//         block.addEventListener('click', function(e) {
//             e.preventDefault();
//             const me = e.currentTarget;
//             const parentEl = me.parentElement;
//             parentEl.setAttribute('data-expanded', parentEl.getAttribute('data-expanded') === 'true' ? 'false' : 'true');
//             parentEl.setAttribute('aria-expanded', parentEl.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
//         });
//     });
// });

document.addEventListener('DOMContentLoaded', function() {
    const questionBlocks = document.querySelectorAll('#faq-container article .question-block');

    if (questionBlocks.length > 0) {
        // Open the first FAQ by default
        toggleFAQ(questionBlocks[0].parentElement, true);

        questionBlocks.forEach(block => {
            block.addEventListener('click', function(e) {
                e.preventDefault();
                const parentEl = block.parentElement;
                const isExpanded = parentEl.getAttribute('aria-expanded') === 'true';
                toggleFAQ(parentEl, !isExpanded);
            });

            // Allow keyboard interaction for accessibility
            block.addEventListener('keydown', function(e) {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    block.click();
                }
            });

            // Add focus and blur events to highlight the question block on keyboard navigation
            block.addEventListener('focus', function() {
                block.classList.add('highlight');
            });

            block.addEventListener('blur', function() {
                block.classList.remove('highlight');
            });

            // Ensure the block can receive focus
            block.setAttribute('tabindex', '0');
        });
    }

    /**
     * Toggles the FAQ item's expanded state.
     * @param {Element} faqElement - The FAQ article element.
     * @param {boolean} expand - Whether to expand or collapse the FAQ item.
     */
    function toggleFAQ(faqElement, expand) {
        faqElement.setAttribute('data-expanded', expand.toString());
        faqElement.setAttribute('aria-expanded', expand.toString());

        const answerElement = faqElement.querySelector('.answer');
        if (answerElement) {
            answerElement.hidden = !expand;
        }

        // Optional: Toggle icon if present
        const icon = faqElement.querySelector('.toggle-icon i');
        if (icon) {
            icon.classList.toggle('fa-arrow-down', !expand);
            icon.classList.toggle('fa-arrow-up', expand);
        }
    }
});
