document.addEventListener('DOMContentLoaded', function() {
    const menuToggle = document.querySelector('.mobile-nav-toggle');
    const nav = document.querySelector('nav.nav-main');

    if (menuToggle && nav) {
        menuToggle.addEventListener('click', function() {
            menuToggle.classList.toggle('active');
            nav.classList.toggle('active');
        });
    }
});